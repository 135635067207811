@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Nova+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/csshake/1.5.3/csshake.min.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

h3, h4, h5, p {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.navbar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
}


.home {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
}

.home-welcomepage {
    height: 90%;
    width: 100%;
    background: radial-gradient(116.65% 97.78% at 50% 97.84%, #ffe75f 0%, #fffbd3 48.96%, #ffffff 100%),linear-gradient(
0deg, #ffffff, #ffffff),#c4c4c4;   
}


.home-welcomepage-content-description {

    background: radial-gradient(114.23% 95.75% at 50% 0%, #ffe75f 0%, #fffbd3 48.96%, #ffffff 100%);
}

.home-welcomepage-content {
    max-width: 1500px;
    margin: auto;
}

.home-welcomepage-content-title {
    position: relative;
    width: 75vw;
    max-width: 1500px;
    padding-top: 30vh;
    margin-left: auto;
    margin-right: auto;
}

.home-welcomepage-content-title h1 {
    font-size: max(7vw, 30px);
    font-weight: bolder;
    line-height: 1;
    font-family: 'Poppins', sans-serif;
}


.home-welcomepage-content-subtitle {
    position: relative;
    width: 75vw;
    max-width: 1500px;
    padding-top: 6vh;
    margin-left: auto;
    margin-right: auto;
}

.home-welcomepage-content-subtitle h3 {
    font-size: 1.1rem;
    line-height: 2rem;
}

#get-started-button-container {
    margin-top: 6vh;
}

.home-welcomepage-content-subtitle a {
    background-color: #D74CFF;
    opacity: 1;
    font-weight: 500;
    border-radius: 10px;
    font-size: 1.1rem;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 45px;
    padding-right: 45px;
    color: #fff;
    text-decoration: none;
}

.home-welcomepage-content-subtitle a:hover {
    background-color: #C74CFF;
}

.description-part {
    position: relative;
    width: 75vw;
    max-width: 1500px;
    padding-top: 30vh;
    margin-left: auto;
    margin-right: auto;
}

#part-1 {
    height: 50px;
}

.description-part h1 {
    font-size: max(4vw, 30px);
    font-weight: bolder;
    line-height: 1;
    font-family: 'Poppins', sans-serif;
}

@media screen and (min-width: 2000px) {
    .home-welcomepage-content-title h1 {
        font-size: 120px
    }

    .title-primary h1 {
        font-size: 120px
    }

    .title-secondary h1 {
        font-size: 80px
    }
}

.gradient-text {
    background: linear-gradient( 
90deg, #C753F8 40%, #f957b5 100% );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.description-part-body {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
}


@media screen and (min-width: 800px) {
    .description-part-body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
    }
}
#sanft-features {
    margin-top: 10vh;
}

#sanft-video {
    margin-top: 10vh;
}


.feature-icon {
    margin-top: 5vh;
}

.feature-icon img {
    border-radius: 10px;
    width: 50px;
    vertical-align: middle;
    margin-right: 1.5vw;
}

.feature-icon h2 {
    font-size: 1.5rem;
}

.stellar-features-list {
    display: grid;
    grid-template-columns: repeat(1, 4fr);
    max-width: 75vw;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    text-align: center;
}

.stellar-features-list-item {
    margin-top: 5vh;
}

@media screen and (min-width: 800px) {
    .stellar-features-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 75vw;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5vh;
        text-align: center;
    }

    .stellar-features-list-item {
        margin-top: 0;
    }
}
    


.stellar-features-list-item-header h2 {
    font-size: 1.2rem;
}

.stellar-project-intro-body-expl {
    margin-top: 5vh;
}


.right {
    text-align: right;
}

.stellar-features-list-item-body {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
}


#next-steps {
    margin-top: 20vh;
    text-align: center;
}


#go-further {
    display: grid;
    grid-template-columns: repeat(1, 2fr);
    max-width: 60vw;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.go-further-card a {
    text-decoration: none;
    font-size: .9rem;
    font-family: "Poppins", sans-serif;
}

@media screen and (min-width: 800px) {
    #go-further {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 60vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .go-further-card a {
        text-decoration: none;
        font-size: 1.2rem;
        font-family: "Poppins", sans-serif;
    }
}
    

.go-further-card {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 20vw;
    max-width: 300px;
    margin: auto;
    min-width: 150px;
    min-height: 300px;
    margin-top: 10vh;
    padding: 20px;
}

.go-further-card-button {
    background-color: #D74CFF;
    opacity: 1;
    font-weight: 500;
    border-radius: 20px;
    padding: 15px;
    color: #fff;
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 15vw;
    min-width: 100px;
    max-width: 200px;
}


#community {
    margin-top: 20vh;
    text-align: center;
}

.community-card {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 20vw;
    margin: auto;
    max-width: 400px;
    min-width: 150px;
    min-height: 150px;
    padding: 20px;
    text-decoration: none;
    margin-top: 10vh;
}

.community-card-button {
    font-size: 3rem;
}

.discord {
    color: #5165F6;
}

.twitter {
    color: #3699E6;
}

.medium {
    color: #000000;
}

.subtitle {
    font-size: 1rem;
    font-family: "Poppins", sans-serif;
    width: 60vw;
    max-width: 1500px;
    margin: auto;
    margin-top: 5vh;
    color: #313131;
    text-align: center;
}

@media screen and (min-width: 800px) {
    #community-body {
        margin: auto;
        margin-top: 20vh;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        text-align: center;
        margin-top: 0;
        max-width: 2000000px
    }
}
    



#logo {
    width: 200px;
}

/*
p {
    font-family: 'DM Sans', sans-serif;
}

p, h1, h2, h3, h4, h5{
    color: #000000;
}

body {
    background-color: #F4F8FD;
}
*/
#horus-collection {
    border-top: 1px solid #dfdfdf;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    max-width: 1500px;
}


#horus-collection h2, p {
    color: #000000;
}

#horus-collection h1 {
    color: #000000;
    font-size: 4rem;
}

.header-showcase-item {
    max-width: 50px;
    transition: transform .5s;
}

.header-showcase-item:hover {
    transform: translateY(-5px);
}
/* Marketplace Nav */




nav {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    z-index: 100;
  }

  #js-menu {
    display: none;
    flex-direction: column;
    justify-content: center;
    padding-left: 0;
  }

.text-li {
    margin: 20px;
}

.icon-li {
    margin: 5px;
}

  #js-menu li {
    display: table;
    list-style-type: none;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    border-bottom-style: solid;
    border-bottom-color: #ccc;
    border-bottom-width: 1px;

  }

  #js-menu li a {
    display: table-cell;
    vertical-align: middle;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    color: #000000;
    text-decoration: none;
}

  #js-menu li:hover {
    opacity: 0.5;
  }
   .header-showcase-item {
        max-width: 100%;
        margin: auto;
        border-radius: 10px;
    }


  @media screen and (min-width: 800px) {
    nav {
      flex-direction: row;

    }

    

.nav-body {
    display: flex;
    width: 100%;
}

  #js-menu {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      flex-direction: row;
      margin-right: 2vw;
    }

    .text-li {
        margin: 0 70px 0 0;
    }

    .icon-li {
        margin: 0 25px 0 0;
    }

  #js-menu li {
      
      text-align: left;
      border-bottom-style: none;
      padding: 0;
    }
  span.nav-toggle {
      display: none;
    }
  }
/* Marketplace header */

.mrk-header {
    width: 100%;
    max-width: 1500px;
    color: #333;
    display: flex;
    text-align: center;
    margin: auto;
    height: 100%;
}

.mrk-header-left {
    margin-top: 4vh;
    position: relative;
    margin-left: auto;
}

.mrk-header-left img {
    max-width: 25rem;
    width: 50vw;
}

.mrk-header-explorer {
    margin-top: 4vh;
    margin-right: auto;
    position: relative;
    width: 20%;
    margin-left: 2vw;
    margin-right: auto;
    text-align: left;
    display: block;
    height: 100%;
    border: .5px solid #bdbdbd;
    border-radius: 10px;
}

.mrk-header-explorer ul {
    list-style-type: none;
    padding-left: 1vw;
}

.expl-it {
    margin-bottom: 5px;
}

.mrk-header-explorer ul li a {
    text-decoration: none;
    color: #383838;
}

.expl-head {
    text-align: left;
    padding-bottom: 10px;
    padding-top: 10px;
    background: linear-gradient(to right, rgb(174, 0, 255), #1100ff);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.expl-head span {
    margin-left: .5vw;
    color: #ffffff;
    font-size: 2rem;
    font-weight: bold;

}


/* Project intro */

.project-intro {
    width: 100%;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 0vh;


}

#main-home {
    top: 50vh;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    z-index: -99;
    position: absolute;
    height: 100vh;
    width: 1280px;
    background: url("static/bg_anim.gif");
    background-repeat: no-repeat;
    opacity: 0.2;
}

.learn-more-btn {
    width: 115px;
    height: 25px;
    color: #ffffff;
    font-size: 1.2rem;
    background: #8C52FF;
    border-radius: 10px;
    padding: 10px;
    text-decoration: none;
}


.project-intro-header {
    position: relative;
    height: 80vh;
    padding-top: 30vh;
}

.project-intro-header h1 {
    font-family: 'DM Sans', sans-serif;
    font-size: 3.5rem;
    max-width: 800px;

    margin: auto;
}

.project-workflow {
    text-align: center;

}

.head-h1 {
    font-size: 3rem;
}


.heading-link {
    font-size: 3rem;
}

.info-link {
    text-decoration: none;
    background-color: #cce0ff;
    border-radius: 5px;
    padding: 5px;
    color: #4892FF;
}

.info-text {
    color: #9b9b9b;
}

.header-showcase {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20vh;
    max-width: 1200px;
    width: 100%;
}


.header-showcase p {
    margin-top: 40px;
    color: #636363;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}



.head-icon {
    
    font-size: 30px;
}

.violet {
    text-decoration: none;
    color: #ffffff;
    opacity: 1;
    background-color: #8C52FF;
    font-size: 1.5rem;
    padding: 15px;
    border-radius: 10px;
}

.btn-1 {
    background-color: #832886;
}

.btn-2 {
    background-color: #283a86;

}


.head-button {
    border-radius: 10px;
    padding: 10px;
    background-color: #8C52FF;
    color: #ffffff;
    font-size: 2rem;
    text-decoration: none;
    animation: lightbulb 1s infinite;
    transition: width 2s, height 2s, transform 2s;
}

.head-button:hover {
    background-color: #ff2dc0;
    color: #ffffff;

}

.rotate-on-hover {
    transition: 0.5s;
}

.rotate-on-hover:hover {
    transform: scale(1.1);
}

.greenish {
    opacity: 1;

    text-decoration: none;
    color: #ffffff;
    
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.head-icon {
    font-size: 30px;
    padding-left: 15px;
    
}

.project-workflow img {
    width: 100%;
    height: auto;
    max-width: 800px;
}

#stellarModLogo {
    border-radius: 20px;
    border: 6px solid #8C52FF;
    width: 15rem;
    vertical-align: middle;
    animation: float .5s infinite;
}

#nftspan {
    border-radius: 20px;
    width: 200px;
    height: 120px;
    vertical-align: text-bottom;
    animation: rotate 3s infinite;
}

.project-intro-body p {
    color: #000;
}

#get-started {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.get-started-item {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 50px;
    margin: 2vw;
}

.get-started-item p {
    font-family: 'DM Sans', sans-serif;
    margin-top: 40px;
}


.project-intro-features {
    margin-top: 4vh;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(200px, auto);
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.project-intro-features img {
    border-radius: 20px;
    width: 60px;
    height: 60px;
}

.intro-card-header {
    padding: 10px;
}

.features-list {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 80px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(120px, auto);
    
}

.features-list-item p {
    color: #585858;
}

.fa-icon {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 20px;
    font-size: 50px;
    color: #8C52FF;
    margin-bottom: 2vh;
}

.intro-card-header:hover img {
    animation: shake 0.3s 1;
}

.project-intro-body-expl {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Marketplace body */

.mrk-body {
    margin-left: 2vw;
    margin-right: 2vw;
}

.mrk-body-showcase {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(200px, auto);
    /*background: rgb(129, 226, 255);
    
    border-bottom: 1px solid #d8d8d8;
*/
    max-width: 1500px;
    margin: 0 auto;
}

#get-started-dest {
    height: 100px;
}

.project-intro-body-1 {
    margin-bottom: 0;
    margin-top: 10vh;
}

/* Marketplace saNFT card*/

.verified-artist {
    color: #5956ff;
}

.acquire-nft-btn {
    width: 120px;
    height: 25px;
    color: #000000;
    font-size: 1.2rem;
    background: #ffffff;
    border: 2px solid #1e2c7a;
    border-radius: 15px;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    text-decoration: none;
}


@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }

@keyframes rotate {
    
    50% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}


@keyframes float {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes changeColor {
    0% {
        color: #ffdeb5;
    }
    50% {
        color: #ff5e5e;
    }
    100% {
        color: #ffdeb5;
    }
}

.sa-nft {
    
    border-radius: 20px;
    text-align: center;
    width: 95%;
    height: 100%;
    margin: auto;
}

.sa-nft-info {
    display: inline-block;
    line-height: 0;
    text-align: left;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: bold;
    color: #888888;
    padding-bottom: 15px;
}

.sa-nft-body {
    display: block;
    height: 100%;
    width: 100%;
    margin-bottom:35px;
    margin-top: 35px;
    overflow: hidden;
}

.img-div {
    max-width: 240px;
    max-height: 240px;
    width: 15vw;
    height: 15vw;
    overflow: hidden;
    margin: 20px auto;
    border-radius: 10px;
    border: 0px solid #ffffff;
    box-shadow: 0px 0px 20px #000000;
}

.img-div img {
    width: 100%;
    transition: 0.3s all ease-in-out;
}

.img-div:hover img {
    transform: scale(1.5);
}

.sa-nft-body img:hover {
    width: 100%;
    transform: scale(1.2);
}

.sa-nft-header h2 {
    font-size: 18px;
}

.sa-nft-header a {
    color: #5cb4fd;
    text-decoration: none;
}

.name-info  {
    line-height: 0;
}

.mrk-body-showcase-collection-title {
    margin-top: 4vh;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 100%;
    height: 100%;
    /*border-top: 1px solid #d6d6d6;*/
}

.mrk-body-showcase-collection-title p {
    font-size: 1.3rem;
}

.mrk-body-showcase-collection-title h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #00ff88;
}

.mrk-body-showcase-collection-title img {
    vertical-align: bottom;
    width: 200px;
    padding-left: 0;

}


.coll-images {
    display: grid;
    max-width: 850px;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(200px, auto);

}


#Horusanfts_name {
    max-width: 400px;
    width: 50vw;
    margin-right: 20px;
}



@font-face{
    font-family: "Mokoto";
    src: url("static/MokotoGlitchMark.ttf") format("truetype");
  }


.item-container {
    margin-top: 5vh;
}

.item-container p, h1, h2, h3, h4, h5, h6 {
    color: #000000;
}

.item-header {
    text-align: center;
    background-color: #8C52FF;
    border-radius: 20px;
    height: 90%;
    max-height: 800px;
}

.item-header p {
    color: #ffffff;
}

.item-header h1 {
    padding-top: 4vh;
    font-size: 3rem;
    font-weight: bold;
    color: #ffffff;
    font-family: Mokoto;
}

.item-header img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
    border-radius: 20px;
}

.item-header img:hover {
    transform: scale(1.2);
}

.item-header p {
    margin: auto;
    max-width: 1000px;
    
    width: 70%;
}

.img-div-head {
    margin-top: 2vh;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    margin-bottom: 5vh;
    aspect-ratio: 1/1;
    max-width: 400px;
    max-height: 400px;
    overflow: hidden;
    border-radius: 20px;
    border: 15px solid #ffffff;

}

.img-div-item {
    padding-bottom: 20px;
}

.img-div-head img {
    transition: 0.3s all ease-in-out;
    transform: scale(1.04);
}

.img-div-head:hover img {
    transform: scale(1.3);
}

.item-footer {
    margin-top: 3vh;
    text-align: center;
}

.item-footer h4 {

    color: #000000;
    font-size: 2.5rem;
}


.item-footer p {
    font-size: .9rem;
    padding: 2px;
}
.item-footer table {
    width: 50%;
    max-width: 1000px;
    margin:auto;
    justify-content: center;
}
table, td, th {
    border: 2px solid #000000;
    border-collapse: collapse;
  }

td p {
    color: #8C52FF;

}

.wallets-integration {
    display: inline-flex;
    margin-top: 5vh;
    width: 60vw;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
    text-align: center;
}

.wallets-integration a {
    color: #ffd3aa;
}

#buy_offer {
    border-radius: 10px;
    border: 2px solid #8b8b8b;
    text-align: center;
    font-size: 1.1rem;
    max-width: 250px;
    font-family: 'Nova Round', cursive;
}

.xbull {
    margin-left: 1vw;
}

::placeholder {
    text-align: center;
    font-size: 1rem;
    font-family: 'Nova Round', cursive;
    opacity: 0.7;
}

.xbull button {
    background: #8C52FF;
    width: 200px;
    height: 50px;
    border: none;
    color: #ffffff;
    border-radius: 10px;
    font-size: 1.1rem;
    font-family: 'Nova Round', cursive;
}

.buy-sanft {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5vh;
    max-width: 1000px;
}

.buy-sanft h1 {
    font-size: 2.5rem;
    font-weight: bold;

}

.buy-sanft-body p {
    font-size: 1.2rem;
    color: #000000;
}

.sanfth1 {
    color: #8C52FF;
}

.nfth1 {
    text-decoration: line-through solid #8C52FF;
}


.buy-sanft-body-resources ul {
    margin-top: 2vh;
    line-height: 1.5;
    list-style-type: none;
    text-align: left;
    max-width: 400px;
    width: 60vw;
    margin-left: auto;
    margin-right: auto;
}

.buy-sanft-body-resources a {
    color: #8C52FF;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bolder;
}


.buy-sanft-body-resources a:hover {
    padding-right: 24px;
    padding-left:8px;
}


.footer {
    width: 100%;
    text-align: center;
}

.footer a {
    color: #5f5f5f;
    text-decoration: none;
    
}


.foot-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 1000px;
    margin: auto;
}


/* rotating cube */

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    perspective: 400;
  }
  
  .sphere {
    width: 140px;
    height: 140px;
    transform-style: preserve-3d;
  }
  .sphere.main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    transform: rotateX(60deg) rotateZ(-30deg);
    animation: rotate 5s infinite linear;
  }
  .sphere.main .circle {
    width: 140px;
    height: 140px;
    position: absolute;
    transform-style: preserve-3d;
    border-radius: 100%;
    box-sizing: border-box;
    box-shadow: 0 0 1px #D74CFF, 
                inset 0 0 1px #D74CFF;
  }
  
  .sphere.main .circle::before {
    transform: translateZ(-90px);
  }
  .sphere.main .circle::after {
    transform: translateZ(90px);
  }
  .sphere.main .circle:nth-child(1) {
    transform: rotateZ(72deg) rotateX(63.435deg);
  }
  .sphere.main .circle:nth-child(2) {
    transform: rotateZ(144deg) rotateX(63.435deg);
  }
  .sphere.main .circle:nth-child(3) {
    transform: rotateZ(216deg) rotateX(63.435deg);
  }
  .sphere.main .circle:nth-child(4) {
    transform: rotateZ(288deg) rotateX(63.435deg);
  }
  .plane.main .circle:nth-child(5) {
    transform: rotateZ(360deg) rotateX(63.435deg);
  }
  .plane.main .circle:nth-child(6) {
    transform: rotateZ(420deg) rotateX(63.435deg);
  }
  
  @keyframes rotate {
    0% {
      transform: rotateX(0) rotateY(0) rotateZ(0);
    }
    100% {
      transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
  }
  
#stage {
    position: absolute;
    visibility: hidden;
    bottom: 40vh;
    right: 25vw;
    opacity: 0.8;
}

@media screen and (min-width: 800px) {
    #stage {
        visibility: visible;
    }
}

@-webkit-keyframes spincube {
    16%      { -webkit-transform: rotateY(-90deg);                }
    33%      { -webkit-transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -webkit-transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -webkit-transform: rotateY(90deg) rotateX(90deg);  }
    83%      { -webkit-transform: rotateX(90deg);                 }
}
@keyframes spincube {
    16%      { -ms-transform: rotateY(-90deg); transform: rotateY(-90deg); }
    33%      { -ms-transform: rotateY(-90deg) rotateZ(90deg); transform: rotateY(-90deg) rotateZ(90deg); }
    50%      { -ms-transform: rotateY(180deg) rotateZ(90deg); transform: rotateY(180deg) rotateZ(90deg); }
    66%      { -ms-transform: rotateY(90deg) rotateX(90deg); transform: rotateY(90deg) rotateX(90deg); }
    83%      { -ms-transform: rotateX(90deg); transform: rotateX(90deg); }
}


#logo-cube {
    z-index: -99;
    margin-left: 40px;
    width: 50px;
    border-radius: 20px;
}

.spinner div {
    position: absolute;
    left: 80px;
    width: 120px;
    height: 120px;
    border: 1px solid #ccc;
    background: rgba(255,255,255,0.8);
    box-shadow: inset 0 0 20px rgba(0,0,0,0.2);
    text-align: center;
    line-height: 120px;
    font-size: 100px;
}

.spinner .face1 { 
    -webkit-transform: translateZ(60px);
    -ms-transform: translateZ(60px);
    transform: translateZ(60px);
}
.spinner .face2 { 
    -webkit-transform: rotateY(90deg) translateZ(60px); 
    -ms-transform: rotateY(90deg) translateZ(60px); 
    transform: rotateY(90deg) translateZ(60px); 
}
.spinner .face3 { 
    -webkit-transform: rotateY(90deg) rotateX(90deg) translateZ(60px); 
    -ms-transform: rotateY(90deg) rotateX(90deg) translateZ(60px); 
    transform: rotateY(90deg) rotateX(90deg) translateZ(60px); 
}
.spinner .face4 { 
    -webkit-transform: rotateY(180deg) rotateZ(90deg) translateZ(60px); 
    -ms-transform: rotateY(180deg) rotateZ(90deg) translateZ(60px); 
    transform: rotateY(180deg) rotateZ(90deg) translateZ(60px); 
}
.spinner .face5 { 
    -webkit-transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px); 
    -ms-transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px); 
    transform: rotateY(-90deg) rotateZ(90deg) translateZ(60px); 
}
.spinner .face6 { 
    -webkit-transform: rotateX(-90deg) translateZ(60px); 
    -ms-transform: rotateX(-90deg) translateZ(60px); 
    transform: rotateX(-90deg) translateZ(60px); 
}

.spinner {
    -webkit-animation: spincube 6s ease-in-out infinite;
    animation: spincube 6s ease-in-out infinite;
    -webkit-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 60px 60px 0;
    -ms-transform-origin: 60px 60px 0;
    transform-origin: 60px 60px 0;
}

#logoCube {
    width: 120px;
}